.button {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  user-select: none;
  border-width: 2px;
  background-color: transparent;
  font-weight: 400;
  padding: 8px 16px;
  border-color: #888888;
  color: #888888;
}

.button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button:hover:not(:disabled) {
  filter: brightness(85%);
  cursor: pointer;
}

.button-h35 {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-w100 {
  width: 100px;
  justify-content: center;
}

.button-green {
  background-color: #5cb85c !important;
  border-color: #5cb85c !important;
  color: #fff !important;
}

.button-yellow {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  color: #fff !important;
}

.button-red {
  background-color: #d9534f !important;
  border-color: #d9534f !important;
  color: #fff !important;
}

.button-blue {
  background-color: #428bca !important;
  border-color: #428bca !important;
  color: #fff !important;
}

.button-cyan {
  background-color: #5bc0de !important;
  border-color: #5bc0de !important;
  color: #fff !important;
}

.button-icon {
  all: unset;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 5px;
}

.button-without-default-cursor {
  all: unset;
  background-color: transparent;
  border: none;
  padding: 5px;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-80 {
  width: 80%;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-80px {
  width: 80px;
}

.w-100px {
  width: 100px;
}

.w-35px {
  width: 35px;
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.h-100px {
  height: 100px;
}

.h-35 {
  height: 35px;
}

.h-50 {
  height: 50px;
}

.unset {
  all: unset;
}

.flex {
  display: flex;
}

.flex.column {
  flex-direction: column;
}

.flex.row {
  flex-direction: row;
}

.flex.center {
  justify-content: center;
  align-items: center;
}

.flex.justify-center {
  justify-content: center;
}

.flex.start {
  justify-content: flex-start;
}

.flex.end {
  justify-content: flex-end;
}

.flex.between {
  justify-content: space-between;
}
.flex.evenly {
  justify-content: space-evenly;
}

.justify-between {
  justify-content: space-between;
}

.flex.align-start {
  align-items: flex-start;
}

.flex.align-center {
  align-items: center;
}

.flex.align-end {
  align-items: flex-end;
}

.pointer {
  cursor: pointer;
}

.gap-025 {
  gap: 0.25rem;
}

.gap-035 {
  gap: 0.35rem;
}

.gap-050 {
  gap: 0.5rem;
}

.gap-075 {
  gap: 0.75rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-4 {
  gap: 4rem;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mt-075 {
  margin-top: 0.75rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-1-2 {
  margin-top: 1.2rem;
}

.mt-2 {
  margin-top: 1.25rem;
}

.mt-3 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 3rem;
}

.ml-05 {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.p-1 {
  padding: 1rem;
}

.p-0 {
  padding: 0;
}

.p-050 {
  padding: 0.5rem;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-end {
  text-align: end;
}

.text-red {
  color: #d43f3a !important;
}

.text-green {
  color: #5cb85c !important;
}

.text-center {
  text-align: center;
}

.w-75px {
  width: 75px;
}

.w-100px {
  width: 100px;
}

.w-150px {
  width: 150px;
}

.w-250px {
  width: 250px;
}

.modal-90w {
  width: 90vw !important;
}

.modal-95w {
  width: 95vw !important;
}

.modal-80w {
  width: 80vw !important;
}

.modal-70w {
  width: 70vw !important;
}

.modal-60w {
  width: 60vw !important;
}

.modal-50w {
  width: 50vw !important;
}

.modal-45w {
  width: 45vw !important;
}

.modal-35w {
  width: 35vw !important;
}

.modal-30w {
  width: 30vw !important;
}

.modal-25w {
  width: 25vw !important;
}

.modal-20w {
  width: 20vw !important;
}
.modal-15w {
  width: 15vw !important;
}

.modal-70h {
  height: 100% !important;
}

.modal-marginTop10 {
  margin-top: 10px !important;
}

.button__hyperlink-style {
  background-color: transparent;
  text-decoration: underline;
  border: none;
  color: #1777f9;
}

.size-400 {
  width: 400px;
  height: 400px;
}

/* Typography Styles */

.typography__title {
  font-size: 18px;
  font-weight: bold;
}

.typography__text-1 {
  font-size: 14px;
  font-weight: normal;
}

.typography__text-2 {
  font-size: 12px;
  font-weight: bold;
}

.typography__caption {
  font-size: 12px;
}

.fontSize-11 {
  font-size: 11px;
}

.fontSize-12 {
  font-size: 12px;
}

.fontSize-14 {
  font-size: 14px;
}

.text-normal {
  font-weight: normal;
}

.text-bold {
  font-weight: bold;
}

.text-red {
  color: #d43f3a;
}

.text-gray1 {
  color: #444444;
}

.text-center {
  display: block;
  text-align: center;
}

.bank-slip__rejected {
  color: red !important;
}

.bank-slip__paid {
  color: green !important;
}

.bank-slip__registered {
  color: green !important;
}

.bank-slip__processing {
  color: #428bca !important;
}

.bank-slip__default {
  color: #000000 !important;
}

.radius-4 {
  border-radius: 4px;
}

.shrink-0 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
